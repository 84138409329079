export const SIGNUP_ERR_CODES = {
  CodeDeliveryFailureException: "code_delivery_failure_exception",
  InternalErrorException: "internal_error_exception",
  InvalidEmailRoleAccessPolicyException:
    "invalid_email_role_access_policy_exception",
  InvalidLambdaResponseException: "invalid_lambda_response_exception",
  InvalidParameterException: "invalid_parameter_exception",
  InvalidPasswordException: "invalid_password_exception",
  InvalidSmsRoleAccessPolicyException:
    "invalid_sms_role_access_policy_exception",
  InvalidSmsRoleTrustRelationshipException:
    "invalid_sms_role_trust_relationship_exception",
  NotAuthorizedException: "not_authorized_exception",
  ResourceNotFoundException: "resource_not_found_exception",
  TooManyRequestsException: "too_many_requests_exception",
  UnexpectedLambdaException: "unexpected_lambda_exception",
  UserLambdaValidationException: "user_lambda_validation_exception",
  UsernameExistsException: "username_exists_exception",
}
