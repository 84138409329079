// @flow
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  memo,
  useMemo,
} from "react"
import { navigate } from "gatsby"
import { RichText } from "prismic-reactjs"

import {
  PROMOTION_SIGNED_LINK_ERRORS,
  setLocalStorage,
} from "@lesmills/gatsby-theme-common"
import type { CreateAccountPageType } from "../../types/CreateAccountPageType"
import type { SubscriptionType } from "../../types/SubscriptionType"
import Amplify from "aws-amplify"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

import {
  awsconfig,
  publicAPI,
  TrialPopover,
  getCreateAccountRoute,
  SENTRY_ACTIONS,
  Signup,
  captureException,
  handleClickOutSideRecaptcha,
  handleVerifyRecaptchaV2,
  handleFailedAPIWithRecaptcha,
  SignupUserWithPromotion,
  deleteLocalStorage,
  isOptInNewsletterCountry,
} from "@lesmills/gatsby-theme-common"
import { SIGNUP_ERR_CODES } from "../../constants/error-codes"

import {
  Textbox,
  PasswordTextbox,
  Button,
  ROUTES,
  linkResolver,
  FormValidator,
  // cookiesStorageConfig,
  Radio,
  getRadioChecked,
  htmlSerializerUpdateStyle,
  checkHaveErrors,
  getLocalStorage,
  NEW_SUBSCRIPTION_KEY,
  getAutomationRecaptcha,
  Spinner,
} from "@lesmills/gatsby-theme-common"

import CreateAccountContainer from "./CreateAccountContainer"
import Popup from "../Popup"
import loadable from "@loadable/component"
import { authStorage } from "@lesmills/gatsby-theme-common/src/services/amplifyStorage"
import { IS_REDIRECT_TO_SIGNUP } from "@lesmills/gatsby-theme-common/src/utils/localstorage"

const { GATSBY_RT_26_07_2022_SYNC_SESSION } = process.env

const ReCAPTCHA = loadable(() => import("react-google-recaptcha"), {
  fallback: <Spinner />,
})
const NotificationToast = loadable(
  () => import("@lesmills/gatsby-theme-common"),
  {
    fallback: <Spinner />,
    resolveComponent: components => components.NotificationToast,
  }
)

const Notification = loadable(() => import("@lesmills/gatsby-theme-common"), {
  fallback: <Spinner />,
  resolveComponent: components => components.Notification,
})

type Props = {|
  prismicData: CreateAccountPageType,
  lang: string,
  selectedSubscription?: SubscriptionType,
  isAffiliate?: Boolean,
  isShowSelectedSubBanner?: Boolean,
  setIsShowSelectedSubBanner?: () => void,
|}

const Account = ({
  prismicData,
  lang,
  selectedSubscription = {},
  isAffiliate,
  setIsShowSelectedSubBanner,
  isShowSelectedSubBanner,
}: Props) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [serverError, setServerError] = useState("")
  const [isExistingAccount, toggleExistingAccountPopup] = useState(false)
  const [isExistingMagentoUser, setExistingMagentoUser] = useState(false)
  const [signedLinkError, setSignedLinkError] = useState("")
  const [internalError, setInternalError] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    subscribe_info: "",
  })
  // https://lesmillsinternational.atlassian.net/browse/AB2B-834
  // Tick "opt in" to emails by default for USA
  const isOptIn = useMemo(() => isOptInNewsletterCountry(), [])
  const [marketingSubscribed, setMarketingSubscribed] = useState(
    isOptIn ? "yes" : ""
  )

  // Ref for textbox
  const emailRef = useRef("")
  const passwordRef = useRef("")
  const firstNameRef = useRef("")
  const lastNameRef = useRef("")
  const confirmEmailRef = useRef("")
  let reCaptchaRef = useRef()
  let requestInput
  const recaptchaAutomation = getAutomationRecaptcha()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const savedSub = getLocalStorage(NEW_SUBSCRIPTION_KEY)

  useEffect(() => {
    Amplify.configure({
      ...awsconfig,
      graphql_endpoint_iam_region: process.env.GATSBY_AWS_COGNITO_REGION,
      graphql_endpoint: process.env.GATSBY_GRAPHQL_PUBLIC_ENDPOINT,
      Auth: {
        authenticationFlowType: process.env.GATSBY_AUTH_FLOW_TYPE,
        ...(GATSBY_RT_26_07_2022_SYNC_SESSION === "true" && {
          storage: authStorage,
        }),
      },
      API: {
        graphql_headers: async () => ({}),
      },
    })
  }, [])

  const {
    account_subtitle = {},
    account_title = {},
    already_have_account = {},
    create_account_loading_button_label = {},
    create_account_continue_button_label = {},
    email_label = {},
    first_name_label = {},
    last_name_label = {},
    password_label = {},
    existing_account_popup_title = {},
    existing_account_popup_subtitle = {},
    existing_account_popup_button = {},
    subscribe_marketing_info = {},
    no_subscribe_marketing_info = {},
    agree_to_privacy_policy_label = {},
    error_on_subscribe_info = {},
    double_check_email_label = {},
    double_check_email_title = {},
    double_check_email_message = {},
    selected_button = {},
    confirm_email_label = {},
    confirm_email_not_match = {},
    confirm_email_required = {},
  } = prismicData || {}

  const validateSingleField = (name, value) => {
    return FormValidator(["REQUIRED", name.toUpperCase()], value, {
      requiredError: prismicData[`${name}_required`].text,
      invalidError:
        name !== "first_name" && name !== "last_name"
          ? prismicData[`${name}_invalid`].text
          : "",
    })
  }

  const redirectUrl = `${getCreateAccountRoute(isAffiliate, lang)}?step=payment`

  const handleValidate = () => {
    const password = passwordRef.current.value
    const firstName = firstNameRef.current && firstNameRef.current.value.trim()
    const lastName =
      lastNameRef.current.value && lastNameRef.current.value.trim()
    const paswordError = validateSingleField("password", password)
    const firstNameError = validateSingleField("first_name", firstName)
    const lastNameError = validateSingleField("last_name", lastName)

    const subscribeInfoError =
      getRadioChecked("subscribeInfo") === ""
        ? error_on_subscribe_info.text
        : ""

    const errors = {
      ...handleValidateConfirmEmail(),
      password: paswordError,
      first_name: firstNameError,
      last_name: lastNameError,
      subscribe_info: subscribeInfoError,
    }

    setInternalError(errors)

    // Validate for Email
    if (checkHaveErrors(errors)) {
      return
    }

    // Mark processing
    setIsProcessing(true)
    if (!recaptchaAutomation) {
      // Only render for not automation test
      verifyRecaptchaV3()
      handleClickOutSideRecaptcha(onClickOutsideRecaptcha)
    } else {
      // Submit without recaptcha render
      handleSubmit()
    }
  }

  const onClickOutsideRecaptcha = () => {
    setIsProcessing(false)
  }

  const handleErrorSignedLink = error => {
    // AB2B-604: Handle error signed link
    window.scrollTo(0, 0)
    setSignedLinkError(
      error === "INVALID_CUSTOMER_TYPE"
        ? PROMOTION_SIGNED_LINK_ERRORS.INVALID_CUSTOMER_TYPE
        : PROMOTION_SIGNED_LINK_ERRORS.SIGNED_LINK_INVALID
    )
  }

  const handleAppSyncError = async (errors = []) => {
    const error = errors.length ? errors[0] : {}
    const { errorType, message } = error
    await handleFailedAPIWithRecaptcha(
      errors,
      () => {
        handleVerifyRecaptchaV2(reCaptchaRef, tokenV2 => {
          handleSubmit(tokenV2, true)
        })
      },
      () => {
        // Turn off loading indicator
        setIsProcessing(false)

        // AB2B-521: Handle error promotion link if errorType is one of PROMOTION_SIGNED_LINK_ERRORS
        if (Object.keys(PROMOTION_SIGNED_LINK_ERRORS).includes(errorType)) {
          handleErrorSignedLink(errorType)
        }

        const isExistingMagentoUserOnLambda =
          errorType === "UserLambdaValidationException" &&
          message
            .toLowerCase()
            .includes("account with the given email already exists")
        if (
          errorType === "UsernameExistsException" ||
          isExistingMagentoUserOnLambda
        ) {
          // This will be used to mark existing user and call sign up after sign in success
          // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-992
          if (isExistingMagentoUserOnLambda) {
            setExistingMagentoUser(true)
          }

          toggleExistingAccountPopup(true)
          // Send error to sentry
          captureException({
            action: SENTRY_ACTIONS.SIGN_UP,
            requestVariables: {
              email: requestInput.email,
              givenName: requestInput.givenName,
              familyName: requestInput.familyName,
              subComms: requestInput.subComms,
              validationData: requestInput.validationData,
            },
            email:
              emailRef.current && emailRef.current.value.trim().toLowerCase(),
            ...error,
          })
          return
        }
        handleError(error)
        return
      }
    )
  }

  // AB2B-607: Handle sign up with signedLink
  const handleSignUpWithPromotion = async (response, email, password) => {
    const { data, errors } = response && response.data
    if (errors && errors.length > 0) {
      await handleAppSyncError(errors)
      return
    }

    if (data.signupUserWithPromotion) {
      setIsProcessing(false)
      const { activeSubscription, valid } = data.signupUserWithPromotion || {}

      // AB2B-521: signed link is valid
      if (valid) {
        // AB2B-521: Show pop up to take you user to sign-in page
        if (activeSubscription) {
          toggleExistingAccountPopup(true)
          // delete subscription in localstorage if extend 2 month suscesfully
          deleteLocalStorage(NEW_SUBSCRIPTION_KEY)
          return
        }

        // AB2B-521: take user to step 2 if user does not have active subs
        handleNewUser(email, password)

        return
      }
    }
  }

  const handleNewUser = (email, password) => {
    // JIRA ticket https://lesmillsinternational.atlassian.net/browse/LA-855
    // Only render for not automation test
    // Navigate to step 2
    navigate(redirectUrl, {
      state: {
        email,
        password,
        // Generate new token to sign in at step 2 if there is no recaptchaAutomation
        ...(recaptchaAutomation && {
          recaptchaAutomation,
        }),
      },
    })
  }

  // Handle error without signedLink
  const handleSignUp = async (response, email, password) => {
    /**
      Success response will be
      "signupUser": {
        "sub": <cognito sub>
        "userConfirmed": true
      }
    */
    const { data, errors } = response && response.data

    if (errors && errors.length > 0) {
      handleAppSyncError(errors)
      return
    }

    if (data.signupUser) {
      handleNewUser(email, password)
    }
  }

  const handleError = err => {
    setIsProcessing(false)
    setServerError(SIGNUP_ERR_CODES[err.errorType])

    // Send error to sentry
    captureException({
      action: SENTRY_ACTIONS.SIGN_UP,
      requestVariables: {
        email: requestInput.email,
        givenName: requestInput.givenName,
        familyName: requestInput.familyName,
        subComms: requestInput.subComms,
        validationData: requestInput.validationData,
      },
      email: emailRef.current && emailRef.current.value.trim().toLowerCase(),
      ...err,
    })
  }

  const verifyRecaptchaV3 = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }
    const tokenV3 = await executeRecaptcha()
    handleSubmit(tokenV3)
  }, [executeRecaptcha])
  /**
   * These param will be returned from onVerify function
   * @param {String} token
   */
  const handleSubmit = (token, isRecaptchaV2 = false) => {
    const emailInput =
      emailRef.current && emailRef.current.value.trim().toLowerCase()
    const passwordInput =
      passwordRef.current && passwordRef.current.value.trim()
    const subComms = getRadioChecked("subscribeInfo") === "yes"

    requestInput = {
      email: emailInput,
      password: passwordInput,
      givenName: firstNameRef.current && firstNameRef.current.value.trim(),
      familyName: lastNameRef.current && lastNameRef.current.value.trim(),
      subComms,
    }

    const validationDataName = isRecaptchaV2
      ? "recaptchaToken"
      : "recaptchaTokenV3"

    if (recaptchaAutomation) {
      requestInput.validationData = [
        {
          Name: validationDataName,
          Value: "",
        },
      ]
      requestInput.captchaSignature = {
        auth: recaptchaAutomation.auth,
        nonce: recaptchaAutomation.nonce,
      }
    } else if (token) {
      requestInput.validationData = [
        {
          Name: validationDataName,
          Value: token,
        },
      ]
    }

    const { givenName, familyName, email, password } = requestInput

    // AB2B-882: can not reproduce empty family name & given name
    // Temporary fix to trigger before calling signUp API
    if (
      !givenName ||
      givenName === "" ||
      !familyName ||
      familyName === "" ||
      !email ||
      email === "" ||
      !password ||
      password === ""
    ) {
      setIsProcessing(false)
      handleValidate()
    } else {
      if (savedSub.signedLink) {
        publicAPI(
          SignupUserWithPromotion,
          res => handleSignUpWithPromotion(res, emailInput, passwordInput),
          handleError,
          {
            userInput: requestInput,
            signedLink: savedSub.signedLink,
          }
        )

        return
      }

      publicAPI(
        Signup,
        res => handleSignUp(res, email, password),
        handleError,
        {
          input: requestInput,
        }
      )
    }
  }

  const handleOnKeyPress = e => {
    if (e.which === 13) {
      e.preventDefault()
      handleValidate()
    }
  }

  // Move handle error to onChange because onKeyPress fires before input.value has been changed
  const handleOnChange = e => {
    // Validate for input
    const name = {
      "create-account-email": "email",
      "create-account-password": "password",
      "create-account-first-name": "first_name",
      "create-account-last-name": "last_name",
      "create-account-confirm-email": "confirm_email",
    }[e.target.getAttribute("id")]

    // Only validate after clicking submit once
    if (internalError[name]) {
      const error =
        name === "confirm_email"
          ? handleValidateConfirmEmail().confirm_email
          : validateSingleField(name, e.target.value)

      if (error !== internalError[name]) {
        setInternalError({
          ...internalError,
          [name]: error,
        })
      }
    }
  }

  const handleValidateConfirmEmail = isOnBlur => {
    const confirmEmail =
      confirmEmailRef.current &&
      confirmEmailRef.current.value.trim().toLowerCase()
    const email =
      emailRef.current && emailRef.current.value.trim().toLowerCase()
    const emailError = validateSingleField("email", email)
    let confirmEmailError = FormValidator(
      ["REQUIRED", "CONFIRM_EMAIL"],
      confirmEmail,
      {
        requiredError: confirm_email_required.text,
        invalidError: confirm_email_not_match.text,
      },
      email
    )

    // only check confirm email if email valid on handle onBlur
    confirmEmailError = isOnBlur
      ? !emailError && confirmEmailError
      : confirmEmailError

    const errors = {
      ...internalError,
      email: emailError,
      confirm_email: confirmEmailError,
    }

    return errors
  }

  const handleOnChangeMarketingSubscribed = e => {
    setMarketingSubscribed(e.target.value)
  }

  return (
    <CreateAccountContainer
      title={account_title.text}
      subtitle={account_subtitle}
      testId="create-account-form"
      classNames={{ wrapper: "animation-face-in" }}
    >
      {signedLinkError ? (
        <Notification
          type="error"
          message={prismicData[signedLinkError].text}
          classNames={{ wrapper: "w-full mt-10" }}
        />
      ) : null}
      {!!serverError && (
        <NotificationToast
          children={<RichText render={prismicData[serverError]} />}
          showHideIcon
          handleHideToast={() => setServerError("")}
          type="error"
        />
      )}
      {isShowSelectedSubBanner && (
        <NotificationToast
          children={
            <p>
              {selectedSubscription.name} {selected_button.text}
            </p>
          }
          showHideIcon
          handleHideToast={() => setIsShowSelectedSubBanner(false)}
          type="success"
          classNames={{
            wrapper: " mt-20",
          }}
        />
      )}
      <Textbox
        label={email_label.text}
        classNames={{
          textbox: "w-full md:h-textbox-lg h-textbox-base md:text-2lg",
          label: "md:text-2lg",
          wrapper: "w-full mt-45",
        }}
        handleOnKeyPress={handleOnKeyPress}
        handleOnChange={handleOnChange}
        id="create-account-email"
        disabled={isProcessing}
        inputRef={emailRef}
        error={internalError.email}
        testId="create-account-email"
      />
      <Textbox
        label={confirm_email_label.text}
        classNames={{
          textbox: "w-full md:h-textbox-lg h-textbox-base md:text-2lg",
          label: "md:text-2lg",
          wrapper: "w-full",
        }}
        handleOnKeyPress={handleOnKeyPress}
        handleOnChange={handleOnChange}
        id="create-account-confirm-email"
        testId="create-account-confirm-email"
        disabled={isProcessing}
        inputRef={confirmEmailRef}
        error={internalError.confirm_email}
        handleOnPaste={e => e.preventDefault()}
        handleOnBlur={e => {
          if (e.currentTarget.value) {
            setInternalError(handleValidateConfirmEmail(true))
          }
        }}
      />
      <div className="w-full mb-30 -mt-15">
        <TrialPopover
          label={double_check_email_label.text}
          title={double_check_email_title.text}
          description={<RichText render={double_check_email_message.raw} />}
          classNames={{ wrapper: "-mt-20", content: "animation-face-in" }}
        />
      </div>
      <PasswordTextbox
        textbox={{
          handleOnKeyPress: handleOnKeyPress,
          handleOnChange: handleOnChange,
          label: password_label.text,
          defaultValue: "",
          inputRef: passwordRef,
          classNames: {
            textbox: "w-full h-textbox-base md:h-textbox-lg md:text-2lg",
            label: "md:text-2lg",
            wrapper: "mb-25 w-full",
          },
          testId: "create-account-password",
          id: "create-account-password",
          disabled: isProcessing,
          error: internalError.password,
        }}
      />
      <Textbox
        label={first_name_label.text}
        classNames={{
          textbox: "w-full md:h-textbox-lg h-textbox-base md:text-2lg",
          label: "md:text-2lg",
          wrapper: "w-full mb-25",
        }}
        handleOnKeyPress={handleOnKeyPress}
        handleOnChange={handleOnChange}
        id="create-account-first-name"
        testId="create-account-first-name"
        disabled={isProcessing}
        inputRef={firstNameRef}
        error={internalError.first_name}
      />
      <Textbox
        label={last_name_label.text}
        classNames={{
          textbox: "w-full md:h-textbox-lg h-textbox-base md:text-2lg",
          label: "md:text-2lg",
          wrapper: "w-full mb-25",
        }}
        handleOnKeyPress={handleOnKeyPress}
        handleOnChange={handleOnChange}
        id="create-account-last-name"
        testId="create-account-last-name"
        disabled={isProcessing}
        inputRef={lastNameRef}
        error={internalError.last_name}
      />

      <Radio
        name="subscribeInfo"
        value="yes"
        label={<RichText render={subscribe_marketing_info.raw} />}
        classNames={{
          wrapper:
            "radio-wrapper font-base-light text-gray-800 leading-tight md:text-base text-3xs text-left mt-10 mb-20",
          checkmark: "radio-checkmark",
        }}
        checked={marketingSubscribed === "yes"}
        handleOnChange={handleOnChangeMarketingSubscribed}
      />

      <Radio
        name="subscribeInfo"
        value="no"
        label={<RichText render={no_subscribe_marketing_info.raw} />}
        classNames={{
          wrapper:
            "radio-wrapper font-base-light text-gray-800 leading-tight md:text-base text-3xs text-left",
          checkmark: "radio-checkmark",
        }}
        checked={marketingSubscribed === "no"}
        handleOnChange={handleOnChangeMarketingSubscribed}
      />

      {internalError.subscribe_info.length > 0 && (
        <Notification
          type="error"
          message={internalError.subscribe_info}
          classNames={{ wrapper: "w-full mt-10" }}
        />
      )}
      <RichText
        render={agree_to_privacy_policy_label.raw}
        htmlSerializer={htmlSerializerUpdateStyle(
          "",
          "font-base-light text-gray-800 leading-tight md:text-base text-3xs mt-25"
        )}
      />

      {/* Recaptcha */}
      {!recaptchaAutomation && (
        <ReCAPTCHA
          ref={reCaptchaRef}
          size="invisible"
          sitekey={process.env.GATSBY_SITE_KEY}
        />
      )}
      <Button
        handleOnClick={handleValidate}
        className="btn btn-primary pt-3 pb-3 w-full md:mb-45 mb-35 g-recaptcha mt-35"
        disabled={isProcessing}
        submitting={isProcessing}
      >
        {isProcessing
          ? create_account_loading_button_label.text
          : create_account_continue_button_label.text}
      </Button>
      <div className="font-base-light leading-6none md:text-xl text-base mb-40 text-gray-600">
        <RichText
          render={already_have_account.raw}
          linkResolver={linkResolver}
        />
      </div>
      {isExistingAccount && (
        <Popup
          title={existing_account_popup_title.text.replace(
            "@first_name",
            firstNameRef.current && firstNameRef.current.value
          )}
          btnSubmitLabel={existing_account_popup_button.text}
          handleSubmit={() => {
            let state = {
              email: emailRef.current && emailRef.current.value.trim(),
            }

            // Pass a flag to mark this is existing Magento user
            if (isExistingMagentoUser && !savedSub.signedLink) {
              state.isExistingMagentoUser = true
            }

            setLocalStorage(IS_REDIRECT_TO_SIGNUP, "true")

            navigate(`${ROUTES(lang).SIGN_IN}?redirect_to=${redirectUrl}`, {
              state,
            })
          }}
          handleDismiss={() => toggleExistingAccountPopup(false)}
        >
          <div className="text-center mb-205 md:mb-45 md:text-2lg text-base text-gray-800 font-base-light">
            <RichText
              render={existing_account_popup_subtitle.raw}
              linkResolver={linkResolver}
              htmlSerializer={htmlSerializerUpdateStyle("", "mb-3")}
            />
          </div>
        </Popup>
      )}
    </CreateAccountContainer>
  )
}

export default memo<Props>(Account)
