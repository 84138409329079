// @flow
import React, { Node, memo } from "react"

import { RichText } from "prismic-reactjs"
import {
  FormTitle,
  linkResolver,
  htmlSerializerUpdateStyle,
} from "@lesmills/gatsby-theme-common"

type Props = {|
  title: string,
  subtitle?: Array,
  children: Node,
  testId?: string,
  classNames?: Object,
|}

const CreateAccountContainer = ({
  title,
  subtitle = {},
  children,
  testId,
  classNames = {},
}: Props) => {
  return (
    <div
      className={`w-full md:w-create-account flex flex-col items-center mb-40 md:mb-140 md:px-0 px-20 ${
        classNames.wrapper ? classNames.wrapper : ""
      }`}
      data-cy={testId}
    >
      <FormTitle
        title={title}
        classNames="text-center md:mt-45 md:mb-35 mt-25 mb-10"
      />
      <RichText
        render={subtitle.raw}
        linkResolver={linkResolver}
        htmlSerializer={htmlSerializerUpdateStyle(
          "",
          "font-base-light text-gray-800 leading-snug md:leading-7none md:text-2lg text-base text-center mb-20 text-3xs"
        )}
      />
      {children}
    </div>
  )
}

export default memo<Props>(CreateAccountContainer)
